<template>
  <vx-card class="edit-cash-box" :class="{'main-box md:w-1/2 top-zero-radius': cashBoxId === 0, 'in-modal': cashBoxId > 0}">
    <vs-input class="w-full"
              :label="`${$t('treasury.cashBoxes.labels.name')}`"
              @keydown.enter="sendData"
              v-model="cash_box.name"/>

    <vs-button id="editCashBoxBTN"
               class="useral-action-button"
               @click="sendData()"/>

    <vs-button id="deleteCashBoxBTN"
               v-if="checkUserPermissions('cash.delete')"
               class="w-full mt-5"
               color="danger"
               @click="$refs.deleteConfirmation.showDialog()">{{ $t('treasury.cashBoxes.labels.delete') }}</vs-button>

    <custom-dialog ref="deleteConfirmation"
                   :title="`${$t('treasury.cashBoxes.confirmations.delete.title')}`"
                   :body="`${$t('treasury.cashBoxes.confirmations.delete.body', {cashBox: cash_box.name})}`"
                   @accept="deleteData()"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {deleteCashBoxes, editCashBoxes, getCashBox} from '@/http/requests/cashBoxes'
import CustomDialog from '@/components/customDialog/customDialog'
import {checkUserPermissions} from "../../../../../../assets/js/functions";

export default {
  name: 'editCashBox',
  components: {CustomDialog},
  metaInfo () {
    return {
      title: this.cash_box.name ? this.$t('treasury.cashBoxes.edit.dynamicTitle', {cashBox: this.cash_box.name}) : this.$t('treasury.cashBoxes.edit.title')
    }
  },
  props: {
    cashBoxId: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      firstChange: true,
      cash_box: {},
      actions: [
        {
          toolbar: [
            {
              id: 'editCashBoxBTN',
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'treasuryCashBox', params: {id: this.$route.params.id}},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    if (this.cashBoxId === 0) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }, 50)
    }

    this.getCashBox()
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    getCashBox () {
      getCashBox(this.cashBoxId ||  this.cash_box.id).then(response => {
        this.cash_box = response.data.data
        this.$store.dispatch('setPageTitle', this.$t('treasury.cashBoxes.edit.dynamicTitle', {cashBox: this.cash_box.name}))
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },

    sendData () {
      const cash_box = {
        id: this.cash_box.id,
        name: this.cash_box.name
      }
      editCashBoxes(cash_box).then(response => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.cashBoxes.notifications.edit.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        if (this.cashBoxId === 0) {
          this.$store.dispatch('removePageChanges')
          this.$router.push({name: 'treasuryCashBox', params: {id: this.$route.params.id}})
        } else {
          this.$emit('edited')
        }
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cashBoxes.notifications.edit.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    },
    deleteData () {
      deleteCashBoxes(this.cashBoxId || this.$route.params.id).then(response => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.cashBoxes.notifications.delete.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        if (this.cashBoxId === 0) {
          this.$store.dispatch('removePageChanges')
          this.$router.push({name: 'treasuryCashBoxes'})
        } else {
          this.$emit('edited')
        }
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cashBoxes.notifications.delete.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    }
  },
  watch: {
    cash_box: {
      handler (val, oldVal) {
        if (!this.firstChange) this.$store.dispatch('setPageChanges')

        if (Object.keys(oldVal).length > 0 && JSON.stringify(val)) {
          this.firstChange = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .edit-cash-box {
    &.in-modal {
      box-shadow: none;

      .vx-card__body {
        padding: 0.5rem;
      }
    }
  }
</style>
